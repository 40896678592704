<template>
  <div class="page-no-access-container">
    非常抱歉，您无权查看该设备或设备不存在
  </div>
</template>

<script>
  export default {
    name: 'no-access',
  }
</script>

<style scoped lang="less">
  .page-no-access-container {
    height: 100vh;
    padding-top: 100px;
    text-align: center;
  }
</style>
